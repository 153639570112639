<template>
	<v-list-item @click="clickItem">
		<v-list-item-avatar>
			<v-icon :color="iconColor">
				{{ icon }}
			</v-icon>
		</v-list-item-avatar>

		<v-list-item-content>
			<v-list-item-title style="word-break: break-all; white-space: normal">
				{{ item.name }}
			</v-list-item-title>
			<v-list-item-subtitle class="d-flex justify-space-between">
				<time :datetime="item.modifiedTime">{{ humanTime }}</time>
				<span v-if="isDir" data-order="-1">&mdash;</span>
				<span v-else :data-order="humanSize">{{ humanSize }}</span>
			</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import filesize from 'filesize'

export default {
	name: 'Listitem',
	props: {
		item: {
			type: Object,
			required: true,
		},
		isDir: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		icon() {
			return this.isDir ? 'mdi-folder' : 'mdi-file-document-outline'
		},
		iconColor() {
			return this.isDir ? '' : 'accent'
		},
		humanSize() {
			if (!this.isDir) {
				return filesize(this.item.size)
			} else {
				return '&mdash;'
			}
		},
		humanTime() {
			return this.$moment(this.item.modifiedTime).fromNow()
		},
		...mapGetters({
			course: 'courses/course',
		}),
	},
	methods: {
		clickItem() {
			if (this.isDir) {
				this.fetchFolder({ course: this.course, folder: this.item })
			} else {
				this.downloadFile(this.item)
			}
		},
		...mapActions('explorer', ['fetchFolder', 'downloadFile']),
	},
}
</script>
